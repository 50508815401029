// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-homepage-claim-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Claim.tsx" /* webpackChunkName: "component---src-pages-homepage-claim-tsx" */),
  "component---src-pages-homepage-footer-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Footer.tsx" /* webpackChunkName: "component---src-pages-homepage-footer-tsx" */),
  "component---src-pages-homepage-group-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Group.tsx" /* webpackChunkName: "component---src-pages-homepage-group-tsx" */),
  "component---src-pages-homepage-groups-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Groups.tsx" /* webpackChunkName: "component---src-pages-homepage-groups-tsx" */),
  "component---src-pages-homepage-header-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Header.tsx" /* webpackChunkName: "component---src-pages-homepage-header-tsx" */),
  "component---src-pages-homepage-info-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Info.tsx" /* webpackChunkName: "component---src-pages-homepage-info-tsx" */),
  "component---src-pages-homepage-info-box-tsx": () => import("/home/circleci/repo/src/pages/Homepage/InfoBox.tsx" /* webpackChunkName: "component---src-pages-homepage-info-box-tsx" */),
  "component---src-pages-homepage-map-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Map.tsx" /* webpackChunkName: "component---src-pages-homepage-map-tsx" */),
  "component---src-pages-homepage-member-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Member.tsx" /* webpackChunkName: "component---src-pages-homepage-member-tsx" */),
  "component---src-pages-homepage-members-tsx": () => import("/home/circleci/repo/src/pages/Homepage/Members.tsx" /* webpackChunkName: "component---src-pages-homepage-members-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/circleci/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/circleci/repo/.cache/data.json")

